<template>
    <div>
        <b-row>
            <b-col xl="3" md="3" sm="6">
                <b-card>
                    <b-card-body style="padding:0px">
                        <b-row style="margin:0px;">
                            <b-col xl="12" sm="12" style="padding-right:0px">
                                <b-media no-body style="text-align:center">
                                    <b-media-aside style="margin-right:0px">
                                        <b-avatar class="b-avatar badge-light-primary rounded-circle" size="48"
                                            variant="light-primary">
                                            <feather-icon size="24" icon="DollarSignIcon" />
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h4 class="font-weight-bolder mb-0">
                                            $ {{parseFloat(this.sumTotalInvestedInProgress +
                                            this.sumTotalInvestedFinished).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                                            "$1,")}}
                                        </h4>
                                        <b-card-text class="font-small-3 mb-0">
                                            Total Invertido
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>


            <b-col xl="3" md="3" sm="6">
                <b-card class="bg-success text-white" style="padding-bottom:0px;">
                    <b-card-body style="padding:0px;margin-bottom:-10px ;">
                        <b-row style="margin-bottom:0px;">
                            <b-col xl="12" sm="12" style="padding-right:10px">
                                <b-media no-body style="text-align:center">
                                    <b-alert show>
                                        <div b-tooltip.hover title="Solicitar Transferencia a mi Banco">
                                            <b-media-aside style="margin-right:0px">
                                                <b-avatar button @click="openModalToTransferMoney" variant="warning"
                                                    class="b-avatar badge-warning rounded-circle" size="48">
                                                    <feather-icon size="24" icon="DownloadIcon" />
                                                </b-avatar>
                                            </b-media-aside>
                                        </div>
                                    </b-alert>
                                    <b-media-body>
                                        <h4 class="font-weight-bolder" style="margin-bottom: 0px;">
                                            $
                                            {{parseFloat(this.user.balance).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                                            "$1,")}}
                                        </h4>
                                        <b-card-text class="font-small-3">
                                            Saldo Sosty
                                            <p style="padding-bottom: 0px;margin-bottom: -15px; font-size: 10px;">
                                                <a style="color:white"
                                                    href="https://sosty.co/politica-de-devoluciones-saldo/"
                                                    target="_blank">Política de devoluciones</a>
                                            </p>
                                        </b-card-text>
                                    </b-media-body>
                                    <b-alert show>
                                        <div b-tooltip.hover title="Ver Extracto">
                                            <b-media-aside style="margin-right:0px">
                                                <b-avatar button @click="showStatementsModal" variant="warning"
                                                    class="b-avatar badge-warning rounded-circle" size="48">
                                                    <feather-icon size="24" icon="ListIcon" />
                                                </b-avatar>
                                            </b-media-aside>
                                        </div>
                                    </b-alert>
                                </b-media>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-0">
            <b-col md="12" lg="12">
                <b-alert variant="info" show>
                    <div class="alert-body">
                        <p>Para información de cuando se termina un proyecto y cuando y como se devuelve el dinero,
                            entra en el siguiente link: <a target="_blank"
                                href="https://www.sosty.co/finalizacion-de-un-proyecto">https://www.sosty.co/finalizacion-de-un-proyecto</a>
                        </p>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <!-- <b-row class="mt-0">
            <b-col md="12" lg="12">
                <b-alert variant="danger" show>
                    <div class="alert-body">
                        <p>El tiempo de procesamiento de transferencias a cuentas bancarias es de entre 10 y 20 días
                            hábiles.</p>
                    </div>
                </b-alert>
            </b-col>
        </b-row> -->

        <b-card-code title="Inversiones Actuales" no-body v-if="investmentsInProgress.length>0">
            <b-table v-if="investmentsInProgress.length>0" ref="selectableTable" :fields="fieldsInvestmentsInProgress"
                :items="investmentsInProgress" responsive selectable class="mb-0" :select-mode="selectMode"
                @row-selected="onRowSelected">>

                <template #cell(projectCode)="data">
                    <p style="margin:0px">{{data.item.project.projectCode}}</p>
                    <b-badge pill variant="success" v-if="data.item.project.hasPartialLiquidation">Liquidación
                        Parcial</b-badge>
                </template>

                <template #cell(projectName)="data">
                    <p style="margin:0px">{{data.item.project.projectName}}</p>
                    <b-badge pill variant="success" v-if="data.item.investment.isGiftReceived">Regalo Recibido</b-badge>
                    <b-badge pill variant="warning" v-if="data.item.investment.isGiftSent">Regalo Enviado</b-badge>
                </template>

                <template #cell(projectStatus)="data">
                    <b-badge pill variant="secondary"> {{ data.item.project.projectStatus }}</b-badge>
                </template>

                <template #cell(endDate)="data">
                    {{((data.item.project.endDate ))}}
                </template>

                <template #cell(isConfirmed)="data">
                    <b-badge v-if="data.item.investment.isConfirmed" pill variant="success">SI</b-badge>
                    <b-badge v-if="!data.item.investment.isConfirmed" pill variant="danger">NO</b-badge>
                </template>

                <template #cell(amountInvested)="data">
                    ${{parseFloat(data.item.investment.amountInvested).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                    "$1,")}}
                </template>

                <template #cell(totalKilograms)="data">
                    <p v-if="data.item.project.projectType == 'Levante' || data.item.project.projectType == 'Ceba'">
                        {{parseFloat((data.item.investment.totalKilograms)).toFixed(2)}} Kg</p>
                    <p v-if="data.item.project.projectType == 'Cria'">
                        {{parseFloat((data.item.investment.totalUnits)).toFixed(2)}} Sty</p>
                </template>

                <template #cell(participation)="data">
                    <p v-if="data.item.project.projectType == 'Levante' || data.item.project.projectType == 'Ceba'">
                        {{parseFloat((data.item.investment.amountInvested/data.item.project.totalMoneyCollected)*100).toFixed(2)}}%
                    </p>
                    <p v-if="data.item.project.projectType == 'Cria'">
                        {{parseFloat((data.item.investment.totalUnits/data.item.project.investmentRequired)*100).toFixed(2)}}%
                    </p>
                </template>

                <template #cell(createDate)="data">
                    {{((data.item.investment.createDate))}}
                </template>

                <template #cell(liquidationButton)="data">
                    <b-button v-if="data.item.liquidationButton" class="btn-sm" variant="outline-success" block
                        @click="showModalLiquidations(data.item)">
                        <span class="align-middle">Liquidaciones</span>
                    </b-button>
                </template>

                <template #cell(followUpProjectButton)="data">
                    <b-button v-if="data.item.followUpProjectButton" class="btn-sm" variant="outline-success" block
                        @click="followUpProjectFromMainButton(data.item)">
                        <span class="align-middle">Seguimiento</span>
                    </b-button>
                </template>
            </b-table>
        </b-card-code>

        <b-card-code title="Inversiones Finalizadas" no-body v-if="investmentsFinished.length>0">
            <b-table v-if="investmentsFinished.length>0" ref="selectableTable" :fields="fieldsInvestmentsFinished"
                :items="investmentsFinished" responsive selectable class="mb-0" :select-mode="selectMode"
                @row-selected="onRowSelected">

                <template #cell(projectCode)="data">
                    <p style="margin:0px">{{data.item.project.projectCode}}</p>
                </template>

                <template #cell(projectName)="data">
                    <p style="margin:0px">{{data.item.project.projectName}}</p>
                    <b-badge pill variant="success" v-if="data.item.investment.isGiftReceived">Regalo Recibido</b-badge>
                    <b-badge pill variant="warning" v-if="data.item.investment.isGiftSent">Regalo Enviado</b-badge>
                </template>

                <template #cell(projectStatus)="data">
                    <b-badge pill variant="secondary"> {{ data.item.project.projectStatus }}</b-badge>
                </template>

                <template #cell(isConfirmed)="data">
                    <b-badge v-if="data.item.investment.isConfirmed" pill variant="success">SI</b-badge>
                    <b-badge v-if="!data.item.investment.isConfirmed" pill variant="danger">NO</b-badge>
                </template>

                <template #cell(amountInvested)="data">
                    ${{parseFloat(data.item.investment.amountInvested).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                    "$1,")}}
                </template>

                <template #cell(totalKilograms)="data">
                    {{parseFloat((data.item.investment.totalKilograms)).toFixed(2)}} Kg
                </template>

                <template #cell(participation)="data">
                    {{parseFloat((data.item.investment.amountInvested/data.item.project.totalMoneyCollected)*100).toFixed(2)}}%
                </template>

                <template #cell(amountReceived)="data">
                    ${{data.item.investment.amountReceived == null ? 0.00 :
                    parseFloat(data.item.investment.amountReceived).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                    "$1,")}}
                </template>

                <template #cell(totalEarned)="data">
                    ${{data.item.investment.amountReceived == null || parseFloat(data.item.investment.amountReceived -
                    data.item.investment.amountInvested).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                    "$1,") < 0 ? 0.00 :parseFloat(data.item.investment.amountReceived -
                        data.item.investment.amountInvested).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"
                        )}} </template>

                        <template #cell(createDate)="data">
                            {{((data.item.investment.createDate))}}
                        </template>

                        <template #cell(liquidationButton)="data">
                            <b-button v-if="data.item.liquidationButton" class="btn-sm" variant="outline-success" block
                                @click="showModalLiquidations(data.item)">
                                <span class="align-middle">Liquidaciones</span>
                            </b-button>
                        </template>

                        <template #cell(followUpProjectButton)="data">
                            <b-button v-if="data.item.followUpProjectButton" class="btn-sm" variant="outline-success"
                                block @click="followUpProjectFromMainButton(data.item)">
                                <span class="align-middle">Seguimiento</span>
                            </b-button>
                        </template>

            </b-table>
        </b-card-code>

        <b-card-code>
            <b-row>
                <b-col v-if="!processing" xl="4" md="6" lg="4" offset-lg="4" offset-xl="4" offset-md="3">
                    <b-button variant="primary" block @click="redirectToSearchProjects">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Buscar Proyectos Para Participar</span>
                    </b-button>
                </b-col>
                <b-col v-if="processing" xl="2" md="4" lg="2" offset-lg="5" offset-xl="5" offset-md="4">
                    <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /></b-button>
                </b-col>
            </b-row>
        </b-card-code>

        <b-modal ref="my-modal" hide-footer title="Que deseas hacer?">
            <b-button class="mt-1" variant="dark" block @click="viewProjectPublicDetails">
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span class="align-middle">Ver Proyecto</span>
            </b-button>
            <b-button class="mt-1" variant="info" block @click="viewProducerDetails">
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span class="align-middle">Ver Perfil Productor</span>
            </b-button>
            <b-button v-if="this.projectStatus == 'Finalizado' && this.investmentReview != true " class="mt-1"
                variant="warning" block @click="showRatingModal">
                <feather-icon icon="StarIcon" class="mr-50" />
                <span class="align-middle mt-1">Calificar Productor</span>
            </b-button>
            <b-button v-if="this.projectStatus == 'Ejecucion' || this.projectStatus == 'Finalizado'" class="mt-1"
                variant="warning" block @click="followUpProject">
                <feather-icon icon="ClipboardIcon" class="mr-50" />
                <span class="align-middle">Seguimiento del Proyecto</span>
            </b-button>
            <b-button
                v-if="this.selectedInvestment != null && this.selectedInvestment.investment != null && this.selectedInvestment.investment.showGiftButton "
                class="mt-1" variant="danger" block @click="giveInvesmentAsGiftModal">
                <feather-icon icon="GiftIcon" class="mr-50" />
                <span class="align-middle">Regalar Inversión</span>
            </b-button>
            <b-button
                v-if="this.downloadingInvoice == false && this.selectedInvestment.investment != null && this.selectedInvestment.investment.siigoInvoiceID != null"
                class="mt-1" variant="success" block @click="downloadInvoice">
                <feather-icon icon="DownloadIcon" class="mr-50" />
                <span class="align-middle">Descargar Factura</span>
            </b-button>
            <b-button v-else-if="this.downloadingInvoice == true" size="lg" variant="success" disabled block>
                <b-spinner small label="Procesando..." />
            </b-button>
        </b-modal>

        <b-modal ref="productorScore" hide-footer title="Calificar Productor">
            <b-row>
                <b-col>
                    <h4 style="line-height: 30px;">
                        Cuéntanos tu experiencia con el productor:
                        <span>
                            <strong style="color:#008D36">
                                {{this.producerName}}
                            </strong>
                        </span>
                        en el proyecto: #
                        <span>
                            <strong style="color:#008D36">
                                {{this.projectCode}}
                            </strong>
                        </span>
                    </h4>
                    <h6 class="mt-2">
                        Seleciona una calificación de 1 a 5, donde 1 es Mala y 5 es Excelente
                    </h6>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-form-rating v-model="producerScore" show-value inline no-border variant="warning" class="d-block"
                    size="lg" />
            </b-row>
            <b-row>
                <b-col md="12" xl="12" class="mb-0">
                    <b-form-group label="Comentario" label-for="basicInput">
                        <!-- <b-form-input v-model="comment" placeholder="Digíte"/> -->
                        <b-form-textarea v-model="userComment" id="textarea-default"
                            placeholder="Digite su experiencia con el productor" rows="5" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" xl="12">
                    <b-button class="mt-1" variant="primary" block @click="saveProducerRating">
                        <feather-icon icon="MailIcon" class="mr-50" />
                        <span class="align-middle mt-3">Enviar Calificación</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal ref="modalForBankAccount" hide-footer title="Solicitud de Transferencia">
            <b-row class="mt-0">
                <b-col md="12" lg="12">
                    <b-alert variant="danger" show>
                        <div class="alert-body">
                            <p>El tiempo de procesamiento de transferencias a cuentas bancarias es de entre 10 y 20 días
                                hábiles.</p>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
            <b-row class="mt-0">
                <b-col md="12" lg="12">
                    <b-alert variant="info" show>
                        <div class="alert-body font-small-1">
                            <span><strong>Confirma tu información bancaria, no podremos realizar el pago si tu
                                    información es incorrecta y no nos hacemos responsables por errores de digitación.
                                </strong></span>
                            <br><br>
                            <span>Número de Cuenta:
                                <strong>{{this.userProfileDetails.bankAccountNumber}}</strong></span>
                            <br>
                            <span>Tipo de Cuenta: <strong>{{this.userProfileDetails.bankAccountType}}</strong></span>
                            <br>
                            <span>Banco: <strong>{{this.userProfileDetails.bankAccountBank}}</strong></span>
                            <br>
                            <span>Identificación: <strong>{{this.userProfileDetails.documentNumber}}</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
            <b-button class="mt-1 mb-1" variant="flat-primary" block @click="viewInvestorDetails">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Click aquí para editar información</span>
            </b-button>

            <p>Digite el monto que desea transferir desde Sosty a su cuenta Bancaria.</p>

            <b-row>
                <b-col>
                    <b-input-group>
                        <b-input-group-prepend is-text style="padding-bottom:14px;padding-top:0px">
                            <feather-icon size="18" icon="DollarSignIcon" style="color:#008D36" />
                        </b-input-group-prepend>
                        <cleave id="number1" style="margin-left:0;" v-model.number="this.originalAmount"
                            value-field="valorATransferir" class="form-control mb-1" :options="options.number"
                            placeholder="Digite" v-on:input="amountOfMoneyToTransferTyped" ref="myTextField" />
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row v-if="this.originalAmount>0" class="mb-1">
                <b-col md="12" lg="12">
                    <div class="alert-body">
                        <span><strong>El costo de la transacción es ${{
                                parseFloat(this.transactionValue).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                                "$1,") }} COP, el valor que recibirás será
                                ${{parseFloat(this.amountToTransfer).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                                "$1,")}} COP</strong></span>
                    </div>
                </b-col>
            </b-row>

            <b-row v-if="this.originalAmount <= 0 || this.originalAmount > this.user.balance" class="mt-0">
                <b-col md="12" lg="12">
                    <b-alert variant="warning" show>
                        <div class="alert-body">
                            <span><strong>El valor debe ser mayor a $0 COP y menor a tu saldo
                                    ${{
                                    parseFloat(this.user.balance).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g,
                                    "$1,")}}</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>



            <b-button v-if="this.originalAmount>0 && this.originalAmount<=this.user.balance" class="mt-1"
                variant="primary" block @click.once="requestTransferMoneyToBankAccount">
                <feather-icon icon="DollarSignIcon" class="mr-50" />
                <span class="align-middle">Confirmar</span>
            </b-button>
            <b-button v-else class="mt-0" variant="primary" block disabled>
                <feather-icon icon="DollarSignIcon" class="mr-50" />
                <span class="align-middle">Confirmar</span>
            </b-button>
        </b-modal>

        <b-modal ref="gaveInvestmentModal" hide-footer title="Regalar Inversión">
            <p>Para poder enviar tu regalo, por favor digita la siguiente información de la persona que lo va a recibir.
            </p>
            <b-row>
                <b-col>
                    <b-form-group label-for="basicInput">
                        <label for="nameReceiver">Nombre</label>
                        <b-form-input v-model="nameReceiver" id="textarea-default" />
                        <label for="email">Email</label>
                        <b-form-input v-model="email" id="textarea-default" />
                        <label for="message">Mensaje</label>
                        <b-form-textarea v-model="message" id="textarea-default" rows="5" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-button class="mt-1" variant="primary" block @click="giveInvestmnentAsAGift">
                <feather-icon icon="GiftIcon" class="mr-50" />
                <span class="align-middle">Enviar Regalo</span>
            </b-button>
        </b-modal>

        <b-modal size="lg" ref="view-statement-modal" title="Extractos" centered hide-footer>
            <view-statement-component :userID=this.userInformation.userID></view-statement-component>
            <b-row class="justify-content-end">
                <b-col class="mt-1 mb-1" md="4" lg="4">
                    <b-button variant="primary" @click="hideStatementsModal" block>
                        Cerrar
                    </b-button>
                </b-col>

            </b-row>
        </b-modal>

        <b-modal size="lg" ref="view-liquidation-modal" title="Liquidaciones" centered hide-footer>
            <liquidations-component :investmentID=this.investmentIDToModal></liquidations-component>
        </b-modal>

        <b-modal size="lg" ref="update-to-invstors" title="Estimados Inversionistas/NeoGanaderos:" centered hide-footer>
            <br>
            <p>Les informamos que nuestra empresa se encuentra en proceso de actualización contable. En este momento, no
                podemos comprometernos con fechas específicas de pago, pero nuestro equipo está trabajando
                diligentemente para ofrecer una respuesta definitiva en los próximos días. Nos comprometemos a
                mantenerlos informados sobre el progreso.</p>
            <br>
            <p>Finalmente, les notificamos que, a partir de la fecha, todas las comunicaciones oficiales se divulgarán
                exclusivamente a través de la plataforma de Sosty.</p>
            <br>
            <p>Agradecemos sinceramente su comprensión y paciencia durante este período.</p>
            <b-row class="justify-content-end">
                <b-col class="mt-1 mb-1" md="4" lg="4">
                    <b-button variant="primary" @click="hideUpdateToInvestorsModal" block>
                        Cerrar
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>


    </div>
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardTitle, BRow, BCol, BMediaAside, BAvatar, BFormRating, BTable, BProgress, BBadge, BModal, BButton, BSpinner, BAlert, BCardText,BCardBody, BMedia,
BFormGroup, BFormInput, BFormTextarea, BMediaBody, BInputGroupPrepend, BTooltip, BInputGroup} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import Cleave from 'vue-cleave-component'
import ViewStatementComponent from '@core/components/ViewStatementComponent'
import LiquidationsComponent from '@core/components/LiquidationsComponent'
// import { codeShowValue } from './code'

export default {
  components: {
    BRow, BCol,
    BCard,
    BCardCode,
    BCardText,
    BCardBody,
    BCardTitle,
    BMediaBody,
    BFormRating,
    BFormTextarea,
    BTable,
    BProgress,
    BBadge,
    BMedia,
    BModal,
    BButton,
    BSpinner,
    BMediaAside,
    BAvatar,
    BAlert,
    BFormGroup, 
    BFormInput,
    BInputGroup,
    BTooltip,
    BInputGroupPrepend,
    Cleave,
    StatisticCardHorizontal,
    ViewStatementComponent,
    LiquidationsComponent
  },
  data() {
    return {
        options: {
            date: {
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
            },
            number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            },
        },
        selectMode: 'single',
        fieldsInvestmentsInProgress: [
            { key: 'projectCode', label: 'Código' },
            { key: 'projectName', label: 'Proyecto' },
            { key: 'producer.fullName', label: 'Productor' },
            { key: 'endDate', label: 'Fecha Finalización Estimada'},
            { key: 'projectStatus', label: 'Fase' },
            { key: 'isConfirmed', label: 'Pago Confirmado' },
            { key: 'amountInvested', label: 'Monto Invertido' }, 
            { key: 'participation', label: 'Porcentaje' }, 
            { key: 'totalKilograms', label: 'Participación' }, 
            { key: 'createDate', label: 'Fecha de Inversión' }, 
            { key: 'liquidationButton', label: 'Ver Liquidaciones' },
            { key: 'followUpProjectButton', label: 'Seguimiento Proyecto' },
        ],
        fieldsInvestmentsFinished: [
            { key: 'projectCode', label: 'Código' },        
            { key: 'projectName', label: 'Proyecto' },
            { key: 'producer.fullName', label: 'Productor' },
            { key: 'projectStatus', label: 'Fase' },
            { key: 'isConfirmed', label: 'Pago Confirmado' },
            { key: 'amountInvested', label: 'Monto Invertido' },
            { key: 'amountReceived', label: 'Total Recibido' },  
            { key: 'totalEarned', label: 'Total Ganado' },  
            { key: 'createDate', label: 'Fecha de Inversión' }, 
            { key: 'liquidationButton', label: 'Ver Liquidaciones' },
            { key: 'followUpProjectButton', label: 'Seguimiento Proyecto' },
        ],        
        investmentsFinished: [],
        investmentsInProgress: [],
        selectedInvestment: {},
        projects:[{}],
        value: 5,        
        comment: null,
        producerScore: null,
        userComment: null,
        projectStatus: null,
        producerName: null,
        projectCode: null,
        investmentReview: null,
        sumTotalInvestedInProgress: null,        
        sumTotalReceibedInProgress: null,
        sumTotalInvestedFinished: null,
        sumTotalReceivedFinished: null,
        balance: null,
        userInformation:{},
        user:{},
        userProfileDetails:{},
        amountToTransfer: null,
        downloadingInvoice: false,
        nameReceiver: null,
        email: null,
        message: null,
        transactionValue:2545,
        originalAmount: null,
        investmentsLiquidations: [{}],
        investmentIDToModal: null,
    }
  },
  created() {
    this.GetInvestmentsFinishedByInvestor();
    this.GetInvestmentsInProgressByInvestor();  
    this.GetUserById(); 
    setTimeout(() => {
        this.showUpdateToInvestorsModal();  
    }, 3000);   
    
  },

watch:{
      amountToTransfer: function(val) { this.amountToTransfer = val; }, 
      originalAmount: function(val) { this.originalAmount = val; }, 
  },

  methods: {
    onRowSelected(items) {
        this.selectedInvestment = items[0];        
        this.investmendID = this.selectedInvestment.investment.investmendID
        if(this.selectedInvestment) this.showModal();
    },
    GetInvestmentsInProgressByInvestor() {
        this.processing = true;
        var userData = getUserData()
        this.userInformation = userData                   
        axios.get('/Investment/GetInvestmentsInProgressByInvestor', { params: { investorID: userData.userID }})
        .then(response => {
            this.processing = false;
            this.investmentsInProgress = response.data.items;            
            
            if(this.investmentsInProgress.length > 0){
                this.investmentsInProgress.forEach(function(item, index){                      
                    item.project.endDate = item.project.endDate.slice(0,10)
                    item.investment.createDate = item.investment.createDate.slice(0,10)
                    if(item.project.investmentRequired != null && item.project.investmentCollected != null){
                        item.project.projectProgress = (item.project.investmentCollected * 100) / item.project.investmentRequired;                       
                    } else {
                        item.project.projectProgress = 0;
                    } 
                    if(item.investment.isGift == true){                        
                        if(item.investment.receiverUserID)
                            item.investment.giftAccepted = true;
                        if(userData.userID == item.investment.investorID){
                            item.investment.isGiftReceived = false;
                            item.investment.isGiftSent = true;
                        }else{
                            item.investment.isGiftReceived = true;
                            item.investment.isGiftSent = false;
                        }                        
                        if(item.investment.isGiftReceived == true)
                            item.investment.showGiftButton = false;
                        else if(item.investment.isGiftSent == true && item.investment.giftAccepted == true)
                            item.investment.showGiftButton = false;
                        else
                            item.investment.showGiftButton = true;
                    } else {
                        item.investment.isGiftReceived = false;
                        item.investment.isGiftSent = false;
                        item.investment.giftAccepted = false;
                        item.investment.showGiftButton = true;                        
                    }                    
                    item.producer.fullName = item.producer.firstName + ' ' + item.producer.lastName;
                    item.liquidationButton = true;
                    item.followUpProjectButton = true;
                });
            }
            this.calculateTotalInvestedInProgress();
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },  
    GetInvestmentsFinishedByInvestor() {
        this.processing = true;
        var userData = getUserData()
        axios.get('/Investment/GetInvestmentsFinishedByInvestor', { params: { investorID: userData.userID }})
        .then(response => {
            this.processing = false;
            this.investmentsFinished = response.data.items;
            if(this.investmentsFinished.length > 0){
                this.investmentsFinished.forEach(function(item, index){                                                     
                        item.investment.createDate = item.investment.createDate.slice(0,10)
                    if(item.project.investmentRequired != null && item.project.investmentCollected != null){
                        item.project.projectProgress = (item.project.investmentCollected * 100) / item.project.investmentRequired;                       
                    } else {
                        item.project.projectProgress = 0;
                    } 
                    if(item.investment.isGift == true){
                        if(item.investment.receiverUserID)
                            item.investment.giftAccepted = true;
                        if(userData.userID == item.investment.investorID){
                            item.investment.isGiftReceived = false;
                            item.investment.isGiftSent = true;
                        }else{
                            item.investment.isGiftReceived = true;
                            item.investment.isGiftSent = false;
                        }
                    } else {
                        item.investment.isGiftReceived = false;
                        item.investment.isGiftSent = false;
                        item.investment.giftAccepted = false;
                    }
                    item.investment.showGiftButton = false;
                    item.producer.fullName = item.producer.firstName + ' ' + item.producer.lastName;
                    item.liquidationButton = true;
                    item.followUpProjectButton = true;
                });
            }
            this.calculateTotalInvestedAndReceivedFinished(); 
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },     
    
    showModal() {
      this.projectStatus = this.selectedInvestment.project.projectStatus
      this.investmentReview = this.selectedInvestment.investment.reviewMade      
      this.$refs['view-liquidation-modal'].hide()
      this.$refs['my-modal'].show()
      
    },
    viewProjectPublicDetails() {
      this.$refs['my-modal'].hide()
      let route = this.$router.resolve({path: 'public-project-details?projectCode=' + this.selectedInvestment.project.projectCode});
      window.open(route.href, '_blank');
    },
    viewProducerDetails() {
      this.$refs['my-modal'].hide()
      let route = this.$router.resolve({path: 'producer-public-profile?producerID=' + this.selectedInvestment.producer.userID});
      window.open(route.href, '_blank');
    },
    redirectToSearchProjects(){
        this.$router.push({ name: 'search-projects' })
    },
    showRatingModal(){
        this.projectCode = this.selectedInvestment.project.projectCode
        this.producerName = this.selectedInvestment.producer.fullName
        this.$refs['productorScore'].show()
    },
    hideRatingModal(){
        this.$refs['productorScore'].hide()
        this.$refs['my-modal'].hide()
    },    
    saveProducerRating(){
        var payload = {
            investorID: this.selectedInvestment.investment.investorID,
            projectID: this.selectedInvestment.project.projectID,
            producerID: this.selectedInvestment.producer.userID,
            userComment: this.userComment,
            producerScore: this.producerScore,
            investmendID: this.investmendID           
        }        
        axios.post('/User/SaveProducerReview', payload)
        .then(response =>{
            if(response){
                this.$bvToast.toast("Gracias por tu calificación", { title: 'Ok', variant: 'success', solid: true, });  
                this.hideRatingModal()
                window.location.reload()   
            } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Wrong', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })        
        this.hideRatingModal()
    },
    followUpProject(){
        this.$refs['my-modal'].hide()
        this.$router.push('follow-up-project?investmendID=' + this.investmendID)
    },
    downloadInvoice(){
        if(this.selectedInvestment.investment.siigoInvoicePDFUrl){
            window.open(this.selectedInvestment.investment.siigoInvoicePDFUrl, '_blank');          
            this.$refs['my-modal'].hide();
        }            
        else{
            this.downloadingInvoice = true;
            axios.get('/Investment/DownloadInvoiceFile', { params: { investmentID: this.selectedInvestment.investment.investmendID}})
            .then(response => {
                this.downloadingInvoice = false;
                window.open(response.data, '_blank');   
                this.$refs['my-modal'].hide();          
            })
        }
    },
    calculateTotalInvestedInProgress(){        
        for(var i = 0; i<this.investmentsInProgress.length; i++){            
            if(this.investmentsInProgress[i].investment.isGiftSent == false){                             
                this.sumTotalInvestedInProgress += this.investmentsInProgress[i].investment.amountInvested            
            }            
        }
        if(!this.sumTotalInvestedInProgress)
                this.sumTotalInvestedInProgress = 0                
    },
    calculateTotalInvestedAndReceivedFinished(){        
        for(var i = 0; i<this.investmentsFinished.length; i++){            
            if(this.investmentsFinished[i].investment.isGiftSent == false){                
                this.sumTotalInvestedFinished += this.investmentsFinished[i].investment.amountInvested
                this.sumTotalReceivedFinished += this.investmentsFinished[i].investment.amountReceived           
            }
        }
        if(!this.sumTotalInvestedFinished)
                this.sumTotalInvestedFinished = 0;            
        if(!this.sumTotalReceivedFinished)
                this.sumTotalReceivedFinished = 0; 
    },
    GetUserById(){
        this.processing = true;        
        axios.get('/User/GetUserbyID', { params: { userID: this.userInformation.userID}})
        .then(response => {
          this.processing = false;
          this.user = response.data
          if(this.user.balance == null){
            this.user.balance = 0
          }
          this.userProfileDetails = response.data.profileDetails[0];                
        })
    },
    openModalToTransferMoney(){
        this.$refs['modalForBankAccount'].show()
    },
    amountOfMoneyToTransferTyped(event){
        this.originalAmount = event
        this.amountToTransfer = this.originalAmount - this.transactionValue

        // this.amountToTransfer = event        
    },
    requestTransferMoneyToBankAccount(){
        var payload = {
            originalAmount: parseFloat(this.originalAmount),
            amountToTransfer: parseFloat(this.amountToTransfer),
            userID: this.userInformation.userID
        }
        axios.post('/MoneyRequest/RequestTransferMoneyToBankAccount', payload)
        .then(response =>{
            if(response){
                this.$refs['modalForBankAccount'].hide()  
                this.$bvToast.toast("Hemos recibido tu solicitud, en breve será procesada por nuestro equipo.", { title: 'Ok', variant: 'success', solid: true, });                                 
                
            } else {
            this.$bvToast.toast("Ocurrió un problema", { title: 'Wrong', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        }) 
    },
    viewInvestorDetails(){    
        this.$router.push('edit-investor-profile');
    },
    giveInvesmentAsGiftModal(){
        this.$refs['my-modal'].hide()
        this.$refs['gaveInvestmentModal'].show()        
    },
    giveInvestmnentAsAGift(){    
        if( !this.nameReceiver || !this.email || !this.message){
            this.$bvToast.toast("Toda la información es obligatória", { title: 'Error', variant: 'danger', solid: true, });
            return;
        }

        var payload = {
            nameReceiver: this.nameReceiver,
            emailReceiver: this.email,
            giftMessage: this.message,
            investmentID: this.investmendID    
        }
        axios.post('/Investment/GiveInvestmnentAsAGift', payload)
        .then(response =>{
            if(response){
                this.$refs['gaveInvestmentModal'].hide()  
                this.$bvToast.toast("Hemos enviado tu regalo", { title: 'Ok', variant: 'success', solid: true, });                                 
                
            } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Wrong', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    showStatementsModal(){
        this.$refs['view-statement-modal'].show()
    },
    hideStatementsModal(){
        this.$refs['view-statement-modal'].hide()
    },    
    showModalLiquidations(event){        
        this.investmentIDToModal = event.investment.investmendID
        this.$refs['view-liquidation-modal'].show()
        this.$refs['my-modal'].hide()
        
    },
    followUpProjectFromMainButton(event){
        this.$router.push('follow-up-project?investmendID=' + event.investment.investmendID)
    },
    showUpdateToInvestorsModal() {
        this.$refs['update-to-invstors'].show()
    },
    hideUpdateToInvestorsModal() {
        this.$refs['update-to-invstors'].hide()
    },
  },
}
</script>
